export default {
  homePage: {
    first: {
      unlimited: '無限流量， 安全連結',
      highSpeed: '高速無痕遊覽， GoDuck 網絡加速器技術',
      ensure: '*為您安全上網保駕護航',
      hint: '*提供基於tsl技術的四層加密信息服務，深度保護您的數據安全'
    },
    second: {
      topTitle: '無論身在何處， GoDuck為您提供高速， 穩定的網絡加速器服務',
      topDesc: '在全球範圍內提供超過300個智能站點， 覆蓋90+國家',
      bottomTitle: '無論何種設備， GoDuck為您提供安全， 便捷的網絡加速器服務',
      bottomDesc:
        'GoDuck 支持市面上的絕大多數主流設備， 保障您的數據隱私。Windows， Mac， iOS， Android均可一鍵連結， 無障礙使用'
    },
    third: {
      title: '下载GoDuck',
      win: 'Windows',
      mac: 'macOS',
      adr: 'Android',
      ios: 'iOS',
      notAvailable: '暫不支持'
    },
    fourth: {
      title: '聯繫我們',
      descOne: '',
      descTwo: '',
      descThree: 'info@goduck.io',
      name: '姓名',
      email: '電郵',
      phone: '電話',
      subject: '主題',
      message: '請在這兒留言。。。',
      submit: '提交',
      nameFault: '姓名不能為空',
      emailFault: '電郵不能為空',
      emailFormatFault: '電郵格式不正確',
      subjectFault: '主題不能為空',
      messageFault: '留言不能超過500字',
      success: '留言成功，我們將有專人與您聯繫',
      fault: '留言失敗，請稍後再試'
    }
  },
  download: {
    win: {
      title: 'Windows 1.3.2',
      version: '支持Win7 / Win8 / Win10 / Win11',
      release: '發布日期： 2023.04.10'
    },
    mac: {
      title: 'macOS 1.3.5',
      version: '支持 macOS 10.12 以上',
      release: '發布日期： 2023.04.10'
    },
    adr: {
      title: 'Android 1.3.3',
      version: '支持 Android 10.0 以上',
      release: '發布日期： 2023.11.01',
      chinaBtn: '大陸下載',
      googleBtn: 'Google Play'
    },
    ios: {
      title: 'iOS 1.3.3',
      version: '支持 iOS 11.0 以上',
      release: '發布日期： 2023.11.01',
      chinaBtn: '大陸下載',
      appBtn: 'App Store'
    }
  },
  subscribe: {
    title: '現在購買， 立享優惠',
    selection: '請選擇支付款道：',
    creditCard: '信用卡',
    unionPay: '銀聯',
    wechatPay: '微信支付',
    crypto: '加密貨幣',
    alipay: '支付寶',
    package: {
      title: 'USD ',
      title2: '/天, 僅限搶購一次',
      perMonth: '每月',
      day: '天'
    },
    btnText: ' 下一步'
  },
  login: {
    username: '用戶名',
    userEmpty: '請輸入用戶名',
    password: '密碼',
    passEmpty: '請輸入密碼',
    signup: '註冊',
    login: '登錄'
  },
  signup: {
    username: '用户名',
    userHint: '* 6-16個字符，可使用英文字母和數字',
    userEmpty: '* 用戶名不能為空',
    userFault: '* 用戶名必須為6-16個字符，只能輸入英文字母和數字',
    password: '密碼',
    passHint: '* 6-16個字符，可使用英文字母、數字和符號',
    passEmpty: '* 密碼不能為空',
    passFault: '* 密碼必須為6-16個字符，只能輸入英文字母、數字和符號',
    passwordThreeDotFault:
      '符號（支持全角+半角）：!" # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _` { | } ~（32個字符）'
  },
  payment: {
    backBtn: '返回主頁',
    paymentSuccess: '支付成功!',
    paymentFailed: '支付失敗',
    paymentEmpty: '訂單檢查失敗',
    createTime: '創建時間',
    orderId: '訂單號碼',
    packageName: '配套名稱',
    name: '名字',
    amount: '金額',
    card: '卡號'
  },
  package: {
    title: '折合 SGD ',
    title2: '/天'
  },
  packageRedirect: {
    1: '一次性付款',
    2: '基本',
    3: '标准',
    4: '高级'
  },
  logoutDialog: {
    title: '確定要退出登錄嗎？',
    content: '退出後將不能查看您的帳戶信息，請謹慎操作'
  },
  footer: {
    aboutUs: '關於我們',
    language: '語言',
    privacy: '隱私協議',
    terms: '服務條款',
    copyright: '© 2023 by Matrix Networking PTE.LTD'
  },
  header: {
    login: '請在這登錄',
    login2: '登錄',
    logout: '登出',
    home: '首頁',
    download: '下載',
    contact: '聯繫我們',
    help: '幫助中心',
    subscribe: '定價',
    vip: 'VIP',
    left: '剩餘',
    days: '天',
    uid: 'UID: '
  },
  global: {
    download: '下載GoDuck',
    subscribe: '訂閱GoDuck服務',
    read: '我已閱讀',
    agreement: '服務條款',
    and: '和',
    privacy: '隱私協議',
    cancel: '取消',
    confirm: '同意',
    back: '返回',
    days: '天',
    close: '關閉'
  },
  alert: {
    logout: '登出成功',
    login: '登錄成功',
    register: '註冊成功',
    status502: '網絡連接失敗，請聯繫管理員',
    loginFault: '用戶名或密碼錯誤',
    registerFault: '用戶名已存在',
    wrong: '請稍候再試'
  },
  errorCode: {
    1000: '內部錯誤',
    1001: '檢查登錄失敗',
    1002: '參數錯誤',
    1003: '用戶名已存在',
    1004: '用戶名或密碼錯誤',
    1005: '密碼錯誤',
    1006: '驗證碼錯誤',
    1007: 'Code不存在',
    1008: 'Code用戶無效',
    1009: '當前設備過多',
    1010: '用戶被禁用',
    1011: '舊密碼錯誤',
    1012: '郵箱已存在',
    1016: '您已購買過此套餐'
  },
  PackageSelect: {
    title: '特惠套餐'
  },
  PayerInfo: {
    title: '請填寫付款人資訊',
    desc: '* 請確保使用與支付平台實名認證一致的姓名付款，切勿填寫任何備註和附註。如資訊有誤將造成交易失敗，請聯絡人工客服。 ',
    isdCode: '地區碼',
    phone: '手機號碼',
    name: '真名',
    formError: {
      phone: '輸入 {digit} 位手機號碼',
      name: '限中文或英文姓名'
    }
  },
  PaymentType: {
    title: '請選擇支付方式',
    desc: {
      0: '* 單價是依照實際使用時間計算的平均費用。 \n請注意，套餐價格以美元 (USD) 報價。結算時將轉換為新加坡元 (SGD)。若使用其他貨幣支付，最終價格將根據您選擇的支付方式和即時匯率進行轉換。 '
    },
    creditCard: '信用卡',
    crypto: '加密貨幣',
    unionPay: '銀聯',
    alipay: '支付寶'
  },
  IsdCode: {
    86: '+86 中國大陸'
  },
  formError: {
    required: '必填'
  },
  pricing: {
    btnNext: '下一步',
    policyStatement: {
      desc: {
        0: '* 點擊下一步即視為您已閱讀並同意',
        1: '《隱私權政策》',
        2: '和',
        3: '《服務條款》',
        4: '。'
      }
    }
  }
}
