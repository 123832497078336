export default {
  homePage: {
    first: {
      unlimited: 'Unlimited data, secure connection',
      highSpeed:
        'High-speed traceless browsing, GoDuck Network Booster Technology',
      ensure: '* ensures you to surf the web safely',
      hint: '* GoDuck provides a four-layer encrypted service based on TSL technology to deeply protect your data security'
    },
    second: {
      topTitle:
        'Enjoy a high-speed and stable Network Booster connection anywhere',
      topDesc:
        'We provide more than 300 ultra-fast Network Booster servers worldwide, covering more than 90 countries.',
      bottomTitle:
        'GoDuck provides a secure and convenient Network Booster service for multi devices',
      bottomDesc:
        'GoDuck Network Booster protects your data privacy and supports most mainstream devices such as Windows, Mac, iOS and Android. Get connected to Network Booster with just one click.'
    },
    third: {
      title: 'Download GoDuck',
      win: 'Windows',
      mac: 'macOS',
      adr: 'Android',
      ios: 'iOS',
      notAvailable: 'Not available'
    },
    fourth: {
      title: 'Contact Us',
      descOne: '',
      descTwo: '',
      descThree: 'info@goduck.io',
      name: 'Name',
      email: 'Email',
      subject: 'Subject',
      message: 'Type your message here...',
      submit: 'Submit',
      nameFault: 'Please enter your name',
      emailFault: 'Please enter your email',
      emailFormatFault: 'Please enter a valid email',
      subjectFault: 'Please enter your subject',
      messageFault: 'Message cannot be more than 500 words.',
      success:
        'Thanks for your submitting! We will contact you as soon as possible.',
      fail: 'Failed to send your message, please try again later'
    }
  },
  download: {
    win: {
      title: 'Windows 1.3.2',
      version: 'version Win7 / Win8 / Win10 / Win11',
      release: 'release date: 10 April 2023'
    },
    mac: {
      title: 'macOS 1.3.5',
      version: 'version macOS 10.12 & above',
      release: 'release date: 10 April 2023'
    },
    adr: {
      title: 'Android 1.3.3',
      version: 'version Android 10.0 & above',
      release: 'release date: 01 November 2023',
      chinaBtn: 'China Mainland',
      googleBtn: 'Google Play'
    },
    ios: {
      title: 'iOS 1.3.3',
      version: 'version iOS 11.0 & above',
      release: 'release date: 01 November 2023',
      chinaBtn: 'China Mainland',
      appBtn: 'App Store'
    }
  },
  subscribe: {
    title: 'Save big, enjoy more!',
    selection: 'Select Payment Method:',
    creditCard: 'Credit Card',
    unionPay: 'Union Pay',
    wechatPay: 'Wechat Pay',
    crypto: 'Crypto',
    alipay: 'Ali Pay',
    package: {
      title: 'USD ',
      title2: '/day, redeem once only',
      perMonth: 'per month',
      day: 'days'
    },
    btnText: ' NEXT'
  },
  login: {
    username: 'User Name',
    userEmpty: 'Please enter your user name',
    password: 'Password',
    passEmpty: 'Please enter your password',
    signup: 'SIGNUP',
    login: 'LOGIN'
  },
  signup: {
    username: 'User Name',
    userHint: '* 6-16 characters, letter and number only',
    userEmpty: '* Username cannot be empty',
    userFault: '* Username must be 6-16 characters, letter and number only',
    password: 'Password',
    passHint: '* 6-16 characters, letters, numbers and special characters',
    passEmpty: '* Password cannot be empty',
    passFault:
      '* Password must be 6-16 characters, letters, numbers and special characters',
    passwordThreeDotFault:
      '* Supported symbol(half-width + full-width)：!" # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _` { | } ~(32 symbols)'
  },
  payment: {
    backBtn: 'Back To Home Page',
    paymentSuccess: 'Payment Successful!',
    paymentFailed: 'Payment Failed',
    paymentEmpty: 'Transaction Check Failed',
    createTime: 'Created Time',
    orderId: 'Order Id',
    packageName: 'Package Name',
    name: 'Name',
    amount: 'Amount',
    card: 'Card Number'
  },
  package: {
    title: 'Converted to SGD ',
    title2: '/day'
  },
  packageRedirect: {
    1: 'ONE-TIME',
    2: 'BASIC',
    3: 'STANDARD',
    4: 'PREMIUM'
  },
  logoutDialog: {
    title: 'Logout Confirmation',
    content: 'Would you like to logout this account?'
  },
  footer: {
    aboutUs: 'About Us',
    language: 'Language',
    privacy: 'Privacy Policy',
    terms: 'User Agreement',
    copyright: '© 2023 by Matrix Networking PTE.LTD'
  },
  header: {
    login: 'Please login here',
    login2: 'Login',
    logout: 'Logout',
    home: 'HOME',
    download: 'DOWNLOAD',
    contact: 'CONTACT',
    help: 'HELP CENTRE',
    subscribe: 'PRICING',
    vip: 'VIP',
    left: 'Left',
    days: 'Days',
    uid: 'UID: '
  },
  global: {
    download: 'Download Now',
    subscribe: 'Subscribe Now',
    read: 'I have read the ',
    agreement: 'User Agreement',
    and: ' &',
    privacy: 'Privacy Policy',
    cancel: 'Cancel',
    confirm: 'Confirm',
    back: 'Back',
    days: 'Days',
    close: 'Close'
  },
  alert: {
    logout: 'Logout Successful',
    login: 'Login Successful',
    register: 'Registered Successful',
    status502: 'Please contact our customer service (502 bad gateway)',
    loginFault: 'Invalid username or password',
    registerFault: 'Username already exist',
    wrong: 'Something went wrong, please try again later.'
  },
  errorCode: {
    1000: 'Internal error',
    1001: 'Check login failed',
    1002: 'Parameter error',
    1003: 'User exist',
    1004: 'Invalid Username or Password',
    1005: 'Invalid password',
    1006: 'Incorrect verification code',
    1007: 'Code does not exist',
    1008: 'Code user invalid',
    1009: 'Too many devices',
    1010: 'User disable',
    1011: 'old password error',
    1012: 'user email is exist',
    1016: 'You have already bought this package'
  },
  PackageSelect: {
    title: 'Special Offer'
  },
  PayerInfo: {
    title: 'Please fill in the payer information',
    desc: '* Ensure the name matches the one registered with the payment platform. Do not add any remarks or messages. Incorrect information may result in transaction failure. Contact customer support if needed.',
    isdCode: 'Area Code',
    phone: 'Cell Phone Number',
    name: 'Real Name',
    formError: {
      phone: 'Enter {digit}-digit cell phone number',
      name: 'Only Chinese name or English name'
    }
  },
  PaymentType: {
    title: 'Select a payment method',
    desc: {
      0: '* The unit price is calculated as the average cost based on actual usage time.\nPlease note, package prices are listed in US dollars (USD) and will be converted to Singapore dollars (SGD) at checkout. If you choose to pay in a different currency, the final price will be adjusted according to your payment method and the current exchange rate.'
    },
    creditCard: 'Credit Card',
    crypto: 'Crypto',
    unionPay: 'Union Pay',
    alipay: 'Ali Pay'
  },
  IsdCode: {
    86: '86 China'
  },
  formError: {
    required: 'Required'
  },
  pricing: {
    btnNext: 'Next',
    policyStatement: {
      desc: {
        0: '* By clicking "Next," you confirm that you have read and agree to the',
        1: 'Privacy Policy',
        2: 'and',
        3: 'Terms of Service',
        4: '.'
      }
    }
  }
}
